import { useAuth } from "@cumulus/event-bus";
import type { AuthHeaders } from "@cumulus/http";

export function useRequests() {
  const loading = ref<boolean>(false);
  const saving = ref<boolean>(false);
  const error = ref<unknown | null>(null);

  const { getAuthHeaders } = useAuth();

  const getRequest = async (fn: (authHeaders: AuthHeaders) => Promise<void>) => {
    loading.value = true;
    error.value = null;

    try {
      const authHeaders = await getAuthHeaders();
      return fn(authHeaders);
    } catch (e) {
      error.value = e;
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const mutationRequest = async (fn: (authHeaders: AuthHeaders) => Promise<void>) => {
    saving.value = true;
    error.value = null;

    try {
      const authHeaders = await getAuthHeaders();
      return fn(authHeaders);
    } catch (e) {
      error.value = e;
      console.error(e);
    } finally {
      saving.value = false;
    }
  };

  return {
    loading,
    saving,
    error,
    getRequest,
    mutationRequest,
  };
}
