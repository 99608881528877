import { AuthHeaders } from "@cumulus/http";
import { Health } from "./dto/StatusHealth";
import StatusApi from "./StatusApi";

class CumulusStatusService {
  async getStatusForAllServices(authHeaders: AuthHeaders): Promise<Array<Health>> {
    const result = await Promise.all([
      StatusApi.getServiceStatus(authHeaders, "Company"),
      StatusApi.getServiceStatus(authHeaders, "Product"),
      StatusApi.getServiceStatus(authHeaders, "Order"),
      StatusApi.getServiceStatus(authHeaders, "Warehouse"),
      StatusApi.getServiceStatus(authHeaders, "Finance"),
      StatusApi.getServiceStatus(authHeaders, "Search"),
      StatusApi.getServiceStatus(authHeaders, "Image"),
      StatusApi.getServiceStatus(authHeaders, "Document"),
      StatusApi.getServiceStatus(authHeaders, "Notification"),
    ]);
    return result as Array<Health>;
  }
}
export default new CumulusStatusService();
