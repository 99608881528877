<template>
  <label for="company-firstName" class="c-required"> {{ t(`company.firstName.label`) }} </label>

  <InputText
    id="company-lastName"
    type="text"
    :modelValue="firstName"
    @update:modelValue="$emit('update:firstName', $event as string)"
    data-testid="company-firstName"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.firstName.$error }"
    :placeholder="t(`company.firstName.placeholder`)"
    maxlength="8000"
  />

  <small class="p-error" v-if="val.firstName.$error" data-testid="company-firstName-error">
    {{ val.firstName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const props = defineProps<{
  firstName: string;
}>();

defineEmits<{
  (e: "update:firstName", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  firstName: {
    required: helpers.withMessage(t("company.firstName.error"), required),
  },
};

const val = useVuelidate(rules, props);
</script>
