import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import InputSwitch from "primevue/inputswitch";
import Chips from "primevue/chips";
import Panel from "primevue/panel";
import InputNumber from "primevue/inputnumber";
import Inplace from "primevue/inplace";
import ProgressSpinner from "primevue/progressspinner";
import InputMask from "primevue/inputmask";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import ToastService from "primevue/toastservice";
import Card from "primevue/card";

import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import SplitButton from "primevue/splitbutton";

import Toast from "primevue/toast";
import Tag from "primevue/tag";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Textarea from "primevue/textarea";
import MultiSelect from "primevue/multiselect";
import Badge from "primevue/badge";

import { createApp } from "vue";
import { i18n } from "./locales/i18n";
import { vue3Debounce } from "vue-debounce";

export default function configureApp(app: ReturnType<typeof createApp>) {
  app.use(PrimeVue);
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);

  app.component("PrimeButton", Button);
  app.component("SplitButton", SplitButton);
  app.component("Checkbox", Checkbox);
  app.component("InputText", InputText);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ColumnGroup", ColumnGroup);
  app.component("InputSwitch", InputSwitch);
  app.component("InputNumber", InputNumber);
  app.component("Inplace", Inplace);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("InputMask", InputMask);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("Dropdown", Dropdown);
  app.component("Divider", Divider);
  app.component("Toast", Toast);

  app.component("ConfirmPopup", ConfirmPopup);
  app.component("Panel", Panel);
  app.component("PrimeTextarea", Textarea);
  app.component("MultiSelect", MultiSelect);
  app.component("Chips", Chips);
  app.component("Tag", Tag);
  app.component("Accordion", Accordion);
  app.component("AccordionTab", AccordionTab);
  app.component("Badge", Badge);
  app.component("Card", Card);

  app.directive(
    "debounce",
    vue3Debounce({
      listenTo: "input",
    })
  );
}
