import { httpClient, AxiosInstance, AuthHeaders } from "@cumulus/http";
import { Health, HealthResult } from "./dto/StatusHealth";

class StatusApi {
  // eslint-disable-next-line
  private exception = (error: any, id: string): Health => {
    const status = error?.response?.status === 503 ? "Unavailable" : "Unhealthy";
    const health = {} as Health;
    health.id = id;
    health.status = status;
    health.results = [
      {
        dependency: id,
        status: status,
      },
    ] as HealthResult[];

    return health;
  };

  async getServiceStatus(authHeaders: AuthHeaders, service: string): Promise<Health> {
    const url = import.meta.env.VITE_APP_API_URL + "/" + service;
    const api = httpClient(url, authHeaders);

    try {
      const health = await this.getHealth(api);
      health.id = service;

      const version = await this.getVersion(api);
      health.version = version.version;
      health.branch = version.branch;
      return health;
    } catch (error) {
      return this.exception(error, service);
    }
  }

  async getHealth(api: AxiosInstance): Promise<Health> {
    return await api.get("/health").then(async ({ data }) => data);
  }

  async getVersion(api: AxiosInstance): Promise<Health> {
    return await api.get("/version").then(async ({ data }) => data);
  }
}

export default new StatusApi();
