<template>
  <div class="c-page-content h-auto !overflow-hidden pb-1">
    <div class="grid grid-cols-12 mt-5 ml-5">
      <div class="c-transition col-span-12">
        <Card>
          <template #title>
            <CompanyToolbar
              v-model:selectedIcon="selectedIcon"
              :isSortingVisible="sortField !== ''"
              @openSortList="openSortList"
              @refreshList="onRefreshList"
              @openColumnChooser="openColumnChooser"
            />
          </template>
          <template #content>
            <div class="mx-4 my-0">
              <div class="flex items-left gap-2 mt-3"></div>

              <CompanyList
                ref="companyListRef"
                :companies="companies"
                :loading="loading"
                :totalHits="totalHits"
                :pageSize="pageSize"
                :page="page"
                :sortField="sortField"
                :sortOrder="sortOrder"
                @update:sortOrder="onUpdateSortOrder"
                @update:sortField="onUpdateSortField"
                @update:page="onUpdatePage"
                @update:pageSize="onUpdatePageSize"
                @selectedIcon="setSelectedIcon"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Company } from "@/repositories/company/model/Company";
import { useCompany } from "@/repositories/company/CompanyService";

import CompanyToolbar from "../search/CompanyToolbar.vue";
import CompanyList from "../components/CompanyList.vue";

const pageSize = ref<number>(50);
const loading = ref(false);
const companies = ref<Company[]>([]);
const totalHits = ref(0);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);
const filteredCompanies = ref<Company[]>([]);

const selectedIcon = ref("");
const setSelectedIcon = (value: string) => {
  selectedIcon.value = value;
};

const { getAllCompanies } = useCompany();

const loadcompanies = async () => {
  loading.value = true;
  try {
    companies.value = await getAllCompanies();

    filteredCompanies.value = companies.value;
    totalHits.value = companies.value.length;
  } catch {
  } finally {
    loading.value = false;
  }
};

onMounted(loadcompanies);

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  loadcompanies();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  loadcompanies();
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onRefreshList = async () => {
  loading.value = true;
  await loadcompanies();
};

const companyListRef = ref();
const openColumnChooser = (event: Event) => {
  companyListRef.value.openColumnChooser(event);
};

const openSortList = (event: Event) => {
  companyListRef.value.openSortList(event);
};
</script>
