<template>
  <div>
    <DataTable
      ref="webshopSearchResult"
      :value="webshops"
      dataKey="id"
      :autoLayout="true"
      :paginator="true"
      responsiveLayout="scroll"
      :resizableColumns="true"
      selectionMode="single"
      columnResizeMode="fit"
      class="c-datatable"
      :loading="loading"
      :rows="pageSize"
      data-testid="webshop-search-table"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      tabindex="0"
      scrollable
      scrollHeight="75vh"
      @row-click="onRowClicked"
    >
      <Column field="domainName" :header="t('webshop.name.label')" sortable />
      <Column field="integrationType" :header="t('webshop.integration-type.label')" sortable />
      <Column field="active" :header="t('webshop.active.label')" sortable>
        <template #body="{ data }">
          <Badge :severity="data.active ? 'success' : 'warning'">{{
            t(`webshop.active.state-${data.active ? "active" : "inactive"}`)
          }}</Badge>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script setup lang="ts">
import type { DataTableRowClickEvent } from "primevue/datatable";
import type { Webshop } from "../models/Webshop";

const { t } = useI18n();

defineProps<{
  webshops: Webshop[];
  loading: boolean;
}>();

const pageSize = ref(50);

const { navigateToEdit } = useNavigation("webshop");
const onRowClicked = (event: DataTableRowClickEvent) => {
  navigateToEdit(event.data.id);
};
</script>

<style scoped lang="scss"></style>
