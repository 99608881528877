import { httpClient, AuthHeaders } from "@cumulus/http";
import { Company } from "./model/Company";
import { CompanyRegistrationInformation } from "./model/CompanyRegistrationInformation";

class CompanyApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != undefined && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY + "/companies"
        : `${import.meta.env.VITE_APP_API_URL as string}/companies`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Company[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, companyId: string): Promise<Company> {
    return await httpClient(this.uri, authHeaders)
      .get(companyId)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, company: CompanyRegistrationInformation): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .post("/", company)
      .then(({ data }) => data);
  }

  public async update(authHeaders: AuthHeaders, company: Company): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", company);
  }

  public async delete(authHeaders: AuthHeaders, companyId: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(companyId);
  }
}

const companyApi = new CompanyApi();
export { companyApi };
