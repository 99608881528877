<template>
  <FloatLabel variant="on">
    <Select
      data-testid="webshop-client"
      class="w-full"
      v-model="selectedValue"
      :options="options"
      optionLabel="name"
      optionValue="id"
      :loading="loading"
      v-tooltip.bottom="{
        value: tooltip,
        showDelay: 1000,
        hideDelay: 300,
        disabled: tooltip === undefined,
      }"
    >
    </Select>

    <label for="webshop-client">
      {{ label }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { NIL as emptyUuid } from "uuid";

const selectedValue = defineModel<string>({
  required: true,
});

const props = defineProps<{
  options: { id: string; name: string }[];
  label: string;
  loading?: boolean;
  tooltip?: string;
}>();

watchEffect(() => {
  if (props.options.length > 0 && (selectedValue.value === emptyUuid || selectedValue.value === "")) {
    selectedValue.value = props.options[0].id;
  }
});
</script>
