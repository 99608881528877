import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const statusRoutes = [
  {
    name: "CumulusStatus",
    path: "/administration/healthstatus",
    component: (): Component => import("@/health-status/StatusList.vue"),
    meta: {
      authRequired: true,
      title: "health-status.title",
    },
  },
];

export const companyRoutes = [
  {
    name: "company-search",
    path: "/administration/company",
    component: (): Component => import("@/company/search/CompanySearch.vue"),
    meta: {
      authRequired: true,
      title: "company.search-company",
    },
  },
  {
    name: "company-add",
    path: "/administration/company/add",
    component: (): Component => import("@/company/add/CompanyAdd.vue"),
    meta: {
      authRequired: true,
      title: "company.add-company",
    },
  },
  {
    name: "company-edit",
    path: "/administration/company/edit/:id",
    component: (): Component => import("@/company/edit/CompanyEdit.vue"),
    meta: {
      authRequired: true,
      title: "company.edit-company",
    },
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("../components/OAuth2Signout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../components/Failed.vue"),
        },
      ]
    : [];
