<template>
  <label for="company-name" class="c-required"> {{ t(`company.name.label`) }} </label>

  <InputText
    id="company-name"
    type="text"
    :modelValue="companyName"
    @update:modelValue="$emit('update:companyName', $event as string)"
    data-testid="company-name"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.companyName.$error }"
    :placeholder="t(`company.name.placeholder`)"
    maxlength="8000"
  />

  <small class="p-error" v-if="val.companyName.$error" data-testid="company-name-error">
    {{ val.companyName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  companyName: string;
}>();

defineEmits<{
  (e: "update:companyName", value: string): void;
}>();

const rules = {
  companyName: {
    required: helpers.withMessage(t("company.name.error"), required),
  },
};

const val = useVuelidate(rules, props);
</script>
