<template>
  <div class="c-content-page flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        data-testid="btn-cancel"
        rounded
        size="small"
        variant="text"
        @click="navigateBack(webshop.id)"
      />
      <div class="inline-block ml-4 text-lg lg:w-36 xl:w-auto truncate xl:text-wrap align-middle">
        <span class="text-slate-800">{{ webshop.domainName }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <WebshopHeader
        :isSaving="saving"
        :hasUpdates="isChanged"
        :unsavedChangesDialogVisible="false"
        @onDelete="deleteWebshop(webshop.id)"
        @onSave="updateWebshop(webshop)"
        @dialogDiscardBtnClicked="navigateToSearch"
      />
    </div>
  </div>

  <BeforeUnloadBrowserTab :hasUpdates="isChanged" />

  <div data-testid="edit-product" class="c-page-content mt-1 ml-5 pr-1" v-if="!error">
    <Card class="m-4">
      <template #content>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-6">
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12">
                <h2 class="text-2xl font-bold dark:text-white">Webshop</h2>
              </div>

              <div class="col-span-12 md:col-span-6">
                <WebshopInputText v-model="webshop.domainName" :label="t(`webshop.name.label`)" required />
              </div>

              <!-- <DefaultLanguage v-model:defaultLanguageIso="webshop.defaultLanguageIso" /> -->

              <div class="flex item-center content-start col-span-12 md:col-span-6">
                <WebshopActive v-model:active="webshop.active" />
              </div>

              <div class="col-span-12 md:col-span-6">
                <WebshopSelect
                  v-model="webshop.integrationType"
                  :options="integrationTypes"
                  :label="t('webshop.integration-type.label')"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <WebshopInputText v-model="webshop.apiUrl" :label="t('webshop.domain.label')" required />
              </div>

              <div class="col-span-12 md:col-span-6">
                <WebshopSelect
                  v-model="webshop.clientId"
                  :options="clients"
                  :label="t('webshop.client.label')"
                  :loading="loadingClients"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <WebshopSelect
                  v-model="webshop.warehouseId"
                  :options="warehouses"
                  :label="t('webshop.warehouse.label')"
                  :loading="loadingWarehouses"
                />
              </div>

              <div class="col-span-12 md:col-span-6">
                <WebshopInputText v-model="webshop.authUsername" label="API Username" />
              </div>

              <div class="col-span-12 md:col-span-6">
                <WebshopInputText v-model="webshop.authPassword" label="API Password" />
              </div>
            </div>
            <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 mt-6">
                <h2 class="text-2xl font-bold dark:text-white">Actions</h2>
              </div>

              <div class="col-span-12 md:col-span-6" v-if="webshop.integrationType === IntegrationType.WooCommerce">
                <SplitButton
                  label="Create webhooks"
                  class="mr-6"
                  @click="createWebhooks(webshop.id, false)"
                  :model="webhookActions"
                />
                <Button label="Delete webhooks" severity="danger" @click="deleteWebhooks(webshop.id)"></Button>
              </div>

              <div
                class="col-span-12 md:col-span-6"
                v-if="webshop.integrationType === IntegrationType.WooCommerce && false"
              >
                <Button label="Update products" class="mr-6"></Button>
                <Button label="Update customers"></Button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>

  <Card class="m-4" v-if="error">
    <template #content>
      <div>{{ t("common.load-error") }} {{ error }}</div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { IntegrationType } from "../models/WebshopIntegrationType";

const { t } = useI18n();
const route = useRoute();

const { navigateToSearch, navigateBack } = useNavigation("webshop");
const { webshop, saving, error, isChanged, fetchWebshopById, updateWebshop, deleteWebshop } = useWebshops();
const { createWebhooks, deleteWebhooks } = useWooCommerce();

const { clients, loading: loadingClients } = useClients();
const { warehouses, loading: loadingWarehouses } = useWarehouses();
const integrationTypes = ref<{ id: string; name: string }[]>([
  { id: IntegrationType.WooCommerce, name: IntegrationType.WooCommerce },
  { id: IntegrationType.Smartstore, name: IntegrationType.Smartstore },
]);

onMounted(async () => {
  const id = route.params.id as string;
  await fetchWebshopById(id);
});

const webhookActions = [
  {
    label: "Create local webhooks",
    command: () => {
      createWebhooks(webshop.value.id, true);
    },
  },
];
</script>

<style lang="scss"></style>
