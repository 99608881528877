import { Address } from "./Address";

export class CompanyRegistrationInformation {
  name = "";
  email = "";
  firstName = "";
  lastName = "";
  registrationDate = new Date(Date.now()).toJSON();
  organizationNumber = "";
  active = true;
  phoneNumber = "";
  address: Address = new Address();
  countryIso = "NO";
  bankName = "";
  bankAccountNumber = "";
  currencyIso = "NOK";
  defaultLanguageIso = "nb-NO";
}
