import { webshopApi } from "../api/WebshopApi";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import type { AuthHeaders } from "@cumulus/http";

export function useWooCommerce() {
  // const { t } = useI18n();

  const toast = useCumulusToast(useToast());
  const { mutationRequest, loading, saving, error } = useRequests();

  const createWebhooks = async (webshopId: string, isLocal: boolean) => {
    mutationRequest(async (authHeaders: AuthHeaders) => {
      await webshopApi.createWebhooks(authHeaders, webshopId, isLocal);

      toast.add({
        severity: "success",
        summary: "Webhooks created",
        detail: "Webhooks have been created successfully.",
        closable: true,
      });
    });
  };

  const deleteWebhooks = async (webshopId: string) => {
    mutationRequest(async (authHeaders: AuthHeaders) => {
      await webshopApi.deleteWebhooks(authHeaders, webshopId);

      toast.add({
        severity: "success",
        summary: "Webhooks deleted",
        detail: "Webhooks have been deleted successfully.",
        closable: true,
      });
    });
  };

  return {
    createWebhooks,
    deleteWebhooks,
    loading,
    saving,
    error,
  };
}
