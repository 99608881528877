<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        data-testid="btn-cancel"
        rounded
        size="small"
        variant="text"
        @click="routeToCompanyList"
      />
      <div class="inline-block ml-4 text-lg lg:w-36 xl:w-auto truncate xl:text-wrap align-middle">
        <span class="text-slate-800">{{ company.organizationNumber }} - {{ company.name }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <CompanyHeader
        :editMode="true"
        :isSaving="isSaving"
        :hasUpdates="hasUpdates"
        :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
        @onDelete="onDeleteCompany"
        @onSave="onSave"
        @onCancel="onCancel"
        @dialogDiscardBtnClicked="routeToCompanySearch"
        @dialogCancelBtnClicked="stayOnPage"
      />
    </div>
  </div>

  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <div data-testid="edit-product" class="c-page-content mt-1 ml-5 pr-1">
    <Card class="m-4">
      <template #content>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 lg:col-span-6">
            <div class="formgrid grid grid-cols-12 gap-4">
              <div class="field col-span-12 md:col-span-6">
                <CompanyName v-model:companyName="company.name" />
              </div>

              <div class="field col-span-12 md:col-span-6">
                <OrganizationNumber v-model:organizationNumber="company.organizationNumber" />
              </div>

              <div class="field col-span-12 md:col-span-6">
                <Email v-model:email="company.email" />
              </div>

              <div class="field col-span-12 md:col-span-6">
                <TelephoneNumber v-model:telephoneNumber="company.phoneNumber" />
              </div>

              <DefaultLanguage v-model:defaultLanguageIso="company.defaultLanguageIso" />

              <ActiveLanguages v-model:activeLanguageIsos="company.activeLanguageIsos" />

              <div class="field col-span-12 md:col-span-6">
                <CumulusDatePicker
                  v-model:date="company.registrationDate"
                  :disabled="true"
                  :label="t(`company.registration-date.label`)"
                  :dataTestId="'company-registration-date'"
                />
              </div>

              <div class="field col-span-12 md:col-span-6">
                <Active v-model:active="company.active" />
              </div>
            </div>
          </div>

          <div class="col-span-12 lg:col-span-6">
            <div class="c-company-address formgrid grid grid-cols-12 gap-4 content-start">
              <Address :address="companyAddressComputed" @setAddress="setCompanyAddress" address-type="company" />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
  <Card class="m-4" v-if="loadFailed">
    <template #content>
      <div>{{ t("common.load-error") }} {{ errorReason }}</div>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import useValidate from "@vuelidate/core";
import { BeforeUnloadBrowserTab, CumulusDatePicker } from "@cumulus/components";

import { InternalServerError, NotFoundError } from "@cumulus/http";
import { useCompany } from "@/repositories/company/CompanyService";
import { Company } from "@/repositories/company/model/Company";
import { Address as AddressModel } from "@/repositories/company/model/Address";

import CompanyName from "../components/CompanyName.vue";
import OrganizationNumber from "../components/OrganizationNumber.vue";
import Email from "../components/Email.vue";
import TelephoneNumber from "../components/TelephoneNumber.vue";
import Address from "../components/Address.vue";
import CompanyHeader from "../components/CompanyHeader.vue";
import Active from "../components/Active.vue";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import DefaultLanguage from "../components/DefaultLanguage.vue";
import ActiveLanguages from "../components/ActiveLanguages.vue";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const route = useRoute();
const router = useRouter();
const { getCompany, updateCompany, deleteCompany } = useCompany();

const loadFailed = ref(false);
const errorReason = ref("");
const isLoading = ref(true);
const isSaving = ref(false);
const unsavedChangesDialogVisible = ref(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const company = ref<Company>(new Company());
const initialCompanyValues = ref<Company>(new Company());

const confirmedDiscard = ref(false);
const routeToCompanyList = async () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/company/search") === -1) {
    router.push({ name: "company-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", company.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const fetchCompanyById = async () => {
  const id = route.params.id as string;

  try {
    company.value = await getCompany(id);
    initialCompanyValues.value = cloneDeep(company.value);
  } catch (error) {
    let errorMessage = "Unknown error";

    if (error instanceof NotFoundError) {
      errorMessage = "Company not found";
    }

    if (error instanceof InternalServerError) {
      errorMessage = "Server error";
    }

    errorReason.value = errorMessage;
    toast.add({
      severity: "error",
      summary: "Failed to load supplier",
      detail: errorReason.value,
      closable: true,
    });
    loadFailed.value = true;
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchCompanyById);

const routeToCompanySearch = async () => {
  router.push({ name: "company-search" });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// Nested validation
const validate = useValidate();

const onSave = async () => {
  //Wait for onblur functions
  await timeout(300);

  validate.value.$touch();

  if (!(await validate.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("common.toast.warn.summary"),
      detail: t("common.toast.warn.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;
    await updateCompany(company.value);

    toast.add({
      severity: "success",
      summary: t("company.toast.success-update.summary"),
      detail: t("company.toast.success-update.detail", { name: company.value.name }),
      closable: true,
    });

    routeToCompanySearch();
  } catch {
    toast.add({
      severity: "warn",
      summary: t("company.toast.error-update.summary"),
      detail: t("company.toast.error-update.detail"),
      closable: true,
    });
  } finally {
    isSaving.value = false;
  }
};

const companyAddressComputed = computed<AddressModel>(() => {
  return company.value.address ? company.value.address : new AddressModel();
});

const setCompanyAddress = (value: AddressModel) => {
  const address = structuredClone(value);
  company.value.address = address;
};

const onDeleteCompany = async () => {
  try {
    isSaving.value = true;

    await deleteCompany(company.value.id);

    toast.add({
      severity: "success",
      summary: t("company.toast.success-delete.summary"),
      detail: t("company.toast.success-delete.detail", { name: company.value.name }),
      closable: true,
    });

    routeToCompanySearch();
  } catch {
    toast.add({
      severity: "error",
      summary: t("company.toast.error-delete.summary"),
      closable: true,
    });
  } finally {
    isSaving.value = false;
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToCompanySearch();
  }
};

const hasUpdates = computed(() => {
  return !isEqual(company.value, initialCompanyValues.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToCompanySearch();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style lang="scss">
.c-company-edit {
  margin: var(--default-content-margin);
}

.c-company-address {
  @media screen and (min-width: 992px) {
    height: 100%;
    margin-left: 0;
    padding-left: 0.5rem;
    border-left: var(--footer-border);
  }
}
</style>
