<template>
  <div class="field col-span-12 md:col-span-6 mt-6">
    <FloatLabel variant="on">
      <Select
        id="default-language"
        data-testid="default-language"
        pt:list:data-testid="language-list"
        class="w-full"
        :options="languages"
        optionLabel="name"
        optionValue="code"
        v-model="defaultLanguageIso"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('company.default-language.label').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      >
        <template #option="slotProps">
          <div class="flex items-center">
            <img
              src="@/assets/flag_placeholder.png"
              :class="'flag flag-' + flagConversion(slotProps.option.code.substring(3).toLowerCase())"
              width="16"
            />
            <div class="ml-4">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Select>
      <label for="default-language">
        {{ t(`company.default-language.label`) }}
      </label>
    </FloatLabel>
    <small class="p-error" v-if="val.defaultLanguageIso.$error" :data-testid="`default-language-error`">
      {{ val.defaultLanguageIso.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { LanguageViewModel } from "@/company/models/LanguageViewModel";

const { t } = useI18n();

const defaultLanguageIso = defineModel<string>("defaultLanguageIso", { required: true, default: "nb-NO" });

const languages = ref<LanguageViewModel[]>([]);
const getlanguages = (): LanguageViewModel[] => {
  return [new LanguageViewModel("nb-NO", "Norsk"), new LanguageViewModel("en-GB", "English")];
};

onMounted(() => {
  languages.value = getlanguages();
});

const flagConversion = (language: string) => {
  switch (language) {
    case "en":
      return "gb";
  }
  return language;
};

const rules = {
  defaultLanguageIso: {
    required,
  },
};

const val = useVuelidate(rules, { defaultLanguageIso: defaultLanguageIso });
</script>
