<template>
  <div id="company-navbar-shortcuts" v-if="useTeleport">
    <Teleport to="#c-navbar-title">
      <h1>{{ t("company.search-header.companies") }}</h1>
    </Teleport>
  </div>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton class="c-circular-button" @click="router.push({ name: 'company-add' })" data-testid="btn-add">
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.add") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button mr-3" @click="router.go(0)">
          <i class="pi pi-sync c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.reload") }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const router = useRouter();
const { t } = useI18n();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");
</script>
