import { Address } from "./Address";

export class Company {
  id = "";
  companyName = "";
  email = "";
  domainPrefix = "";
  registrationDate = "";
  organizationNumber = "";
  telephoneNumber = "";
  companyAddress: Address = new Address();
  active = false;
}
