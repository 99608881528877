import { clientApi } from "@/api/client/ClientApi";
import type { Client } from "@/api/client/model/Client";
import type { AuthHeaders } from "@cumulus/http";

export function useClients() {
  const clients = ref<Client[]>([]);

  const { getRequest, error, loading } = useRequests();

  const fetchClients = async () => {
    getRequest(async (authHeaders: AuthHeaders) => {
      clients.value = await clientApi.getAll(authHeaders);
    });
  };

  onMounted(fetchClients);

  return { clients, loading, error };
}
