<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <CompanyHeader
    :editMode="false"
    :isSaving="isSaving"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @onSave="onCreate"
    @onCancel="onCancel"
    @dialogDiscardBtnClicked="routeToCompanySearch"
    @dialogCancelBtnClicked="stayOnPage"
  />
  <div class="c-company-add">
    <div class="c-card">
      <h2>{{ t("company.create-step-1") }}</h2>

      <div class="grid">
        <div class="col-12 lg:col-6">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <CompanyName v-model:companyName="company.companyName" />
            </div>

            <div class="field col-12 md:col-6">
              <OrganizationNumber v-model:organizationNumber="company.organizationNumber" />
            </div>

            <div class="field col-12 md:col-6">
              <FirstName v-model:firstName="company.firstName" />
            </div>

            <div class="field col-12 md:col-6">
              <LastName v-model:lastName="company.lastName" />
            </div>

            <div class="field col-12 md:col-6">
              <Email v-model:email="company.email" />
            </div>

            <div class="field col-12 md:col-6">
              <TelephoneNumber v-model:telephoneNumber="company.telephoneNumber" />
            </div>

            <Banking v-model:bankingName="company.banking.name" v-model:accountNumber="company.banking.accountNumber" />

            <Currency v-model:currency="company.currencyIso" />

            <div class="field col-12 md:col-6">
              <CumulusDatePicker
                v-model:date="company.registrationDate"
                :disabled="false"
                :label="t(`company.registration-date.label`)"
                :dataTestId="'company-registration-date'"
              />
            </div>

            <div class="field col-12 md:col-6">
              <Active v-model:active="company.active" />
            </div>
          </div>
        </div>

        <div class="col-12 lg:col-6">
          <div class="c-company-address formgrid grid align-content-start">
            <Address :address="companyAddressComputed" @setAddress="setCompanyAddress" address-type="company" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import useValidate from "@vuelidate/core";
import { BeforeUnloadBrowserTab, CumulusDatePicker } from "@cumulus/components";

import { Address as AddressModel } from "@/repositories/company/model/Address";
import { useCompany } from "@/repositories/company/CompanyService";
import { CompanyRegistrationInformation } from "@/repositories/company/model/CompanyRegistrationInformation";

import CompanyName from "../components/CompanyName.vue";
import OrganizationNumber from "../components/OrganizationNumber.vue";
import Email from "../components/Email.vue";
import FirstName from "../components/FirstName.vue";
import LastName from "../components/LastName.vue";
import TelephoneNumber from "../components/TelephoneNumber.vue";
import Banking from "../components/Banking.vue";
import Currency from "../components/Currency.vue";
import Address from "../components/Address.vue";
import CompanyHeader from "../components/CompanyHeader.vue";
import Active from "../components/Active.vue";
import isEqual from "lodash.isequal";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();
const { createCompany } = useCompany();

const company = ref<CompanyRegistrationInformation>(new CompanyRegistrationInformation());
const initialCompanyValues = ref<CompanyRegistrationInformation>(new CompanyRegistrationInformation());
const isSaving = ref(false);
const unsavedChangesDialogVisible = ref(false);
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const routeToCompanySearch = () => {
  router.push({ name: "company-search" });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onCreate = async () => {
  //Wait for onblur functions
  await timeout(300);

  validate.value.$touch();
  await validate.value.$validate();

  if (validate.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.toast.warn.summary"),
      detail: t("common.toast.warn.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;

    await createCompany(company.value);

    toast.add({
      severity: "success",
      summary: t("company.toast.success-add.summary"),
      detail: t("company.toast.success-add.detail", { name: company.value.companyName }),
      closable: true,
    });

    routeToCompanySearch();
  } finally {
    isSaving.value = false;
  }
};

const companyAddressComputed = computed<AddressModel>(() => {
  return company.value.companyAddress ? company.value.companyAddress : new AddressModel();
});

const setCompanyAddress = (value: AddressModel) => {
  const address = structuredClone(value);
  company.value.companyAddress = address;
  if (address.countryIso !== "") {
    company.value.countryIso = address.countryIso;
  }
};

// Nested validation
const validate = useValidate();

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToCompanySearch();
  }
};

const hasUpdates = computed(() => {
  return !isEqual(company.value, initialCompanyValues.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToCompanySearch();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style lang="scss">
.c-company-add {
  margin: var(--default-content-margin);
}

.c-company-address {
  @media screen and (min-width: 992px) {
    height: 100%;
    margin-left: 0;
    padding-left: 0.5rem;
    border-left: var(--footer-border);
  }
}
</style>
