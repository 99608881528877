<template>
  <div class="field col-span-12 md:col-span-6 mt-6">
    <FloatLabel
      variant="on"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('company.active-languages.label').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    >
      <MultiSelect
        id="active-languages"
        data-testid="active-languages"
        pt:list:data-testid="language-list"
        class="w-full"
        :options="languages"
        optionLabel="name"
        optionValue="code"
        v-model="activeLanguageIsos"
      >
        <template #option="slotProps">
          <div class="flex items-center">
            <img
              src="@/assets/flag_placeholder.png"
              :class="'flag flag-' + flagConversion(slotProps.option.code.substring(3).toLowerCase())"
              width="16"
            />
            <div class="ml-4">{{ slotProps.option.name }}</div>
          </div>
        </template>
      </MultiSelect>
      <label for="active-languages">
        {{ t(`company.active-languages.label`) }}
      </label>
    </FloatLabel>

    <small class="p-error" v-if="val.activeLanguageIsos.$error" :data-testid="`active-languages-error`">
      {{ val.activeLanguageIsos.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { LanguageViewModel } from "@/company/models/LanguageViewModel";

const { t } = useI18n();

const activeLanguageIsos = defineModel<string[]>("activeLanguageIsos", { required: true, default: ["nb-NO"] });

const languages = ref<LanguageViewModel[]>([]);
const getlanguages = (): LanguageViewModel[] => {
  return [
    new LanguageViewModel("nb-NO", t("common.language-iso.nb-no")),
    new LanguageViewModel("en-GB", t("common.language-iso.en-gb")),
    new LanguageViewModel("da-DK", t("common.language-iso.da-dk")),
    new LanguageViewModel("sv-SE", t("common.language-iso.sv-se")),
  ];
};

onMounted(() => {
  languages.value = getlanguages();
});

const flagConversion = (language: string) => {
  switch (language) {
    case "en":
      return "gb";
  }
  return language;
};

const rules = {
  activeLanguageIsos: {
    required,
  },
};

const val = useVuelidate(rules, { activeLanguageIsos: activeLanguageIsos });
</script>
