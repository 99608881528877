<template>
  <label for="company-lastName" class="c-required"> {{ t(`company.lastName.label`) }} </label>

  <InputText
    id="company-lastName"
    type="text"
    :modelValue="lastName"
    @update:modelValue="$emit('update:lastName', $event as string)"
    data-testid="company-lastName"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.lastName.$error }"
    :placeholder="t(`company.lastName.placeholder`)"
    maxlength="8000"
  />

  <small class="p-error" v-if="val.lastName.$error" data-testid="company-lastName-error">
    {{ val.lastName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const props = defineProps<{
  lastName: string;
}>();

defineEmits<{
  (e: "update:lastName", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  lastName: {
    required: helpers.withMessage(t("company.lastName.error"), required),
  },
};

const val = useVuelidate(rules, props);
</script>
