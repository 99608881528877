<template>
  <label for="company-active"> {{ t(`company.active.label`) }} </label>

  <Checkbox
    inputId="company-active"
    v-model="checked"
    :binary="true"
    data-testid="company-active"
    class="inputfield w-full"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  active: boolean;
}>();

const emit = defineEmits<{
  (e: "update:active", value: boolean): void;
}>();

const checked = computed<boolean>({
  get: () => {
    return props.active;
  },
  set: (value) => {
    emit("update:active", value);
  },
});
</script>
