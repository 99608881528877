import router from "@/router/router";

export function useNavigation(entity: string) {
  const navigateBack = async (id: string) => {
    if (window.history.state.back === null || window.history.state.back.indexOf("/" + entity + "/search") === -1) {
      router.push({ name: entity + "-search" });
    } else {
      const url = new URL(window.location.origin + window.history.state.back);
      url.searchParams.set("focusResult", id);
      router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
    }
  };

  const paramsToObject = (entries: URLSearchParams) => {
    const result = {} as Record<string, string>;
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  const navigateToEdit = async (id: string) => {
    router.push({ name: entity + "-edit", params: { id } });
  };

  const navigateToSearch = async () => {
    router.push({ name: entity + "-search" });
  };

  return { navigateToEdit, navigateToSearch, navigateBack };
}
