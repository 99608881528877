<template>
  <label for="company-email" class="c-required"> {{ t(`company.email.label`) }} </label>

  <InputText
    id="company-email"
    type="email"
    v-model="emailComputed"
    data-testid="company-email"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.email.$error }"
    :placeholder="t(`company.email.placeholder`)"
    maxlength="8000"
  />

  <small class="p-error" v-if="val.email.$error" data-testid="company-email-error">
    {{ val.email.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, email as emailVal, helpers } from "@vuelidate/validators";
import { computed } from "vue";

const { t } = useI18n();

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const emailComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const rules = {
  email: {
    required: helpers.withMessage(t("company.email.error"), required),
    emailVal,
  },
};

const val = useVuelidate(rules, props);
</script>
