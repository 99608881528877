<template>
  <div class="field col-12 md:col-6">
    <label for="company-currency" class="c-required"> {{ t(`company.currency.label`) }} </label>
    <Dropdown
      id="company-currency"
      :options="currencies"
      optionLabel="iso"
      optionValue="iso"
      data-testid="company-currency"
      v-model="selectedCurrency"
      class="w-full"
      :class="{ 'p-invalid': val.currency.$error }"
      :placeholder="t('company.currency.placeholder')"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    />
    <small data-testid="company-currency-error" class="p-error" v-if="val.currency.$error">{{
      val.currency.$errors[0].$message
    }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { currencyRepository } from "@/repositories/currency/CurrencyRepository";
import { Currency } from "@/repositories/currency/model/Currency";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const { t } = useI18n();

const props = defineProps<{
  currency: string;
}>();

const emit = defineEmits<{
  (e: "update:currency", value: string): void;
}>();

const isActive = ref(false);

const selectedCurrency = computed<string>({
  get: () => {
    return props.currency;
  },
  set: (value) => {
    emit("update:currency", value);
  },
});

const currencies = ref<Currency[]>([]);
const fetchCurrencies = async () => {
  currencies.value = await currencyRepository.getAll();
};
onMounted(fetchCurrencies);

const rules = {
  currency: {
    required: helpers.withMessage(t("company.currency.error"), required),
  },
};

const val = useVuelidate(rules, props);
</script>

<style lang="scss">
#company-currency-iso {
  text-transform: uppercase;
}
</style>
