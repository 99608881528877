import PrimeVue from "primevue/config";
import Aura from "@primeuix/themes/aura";
import { definePreset } from "@primeuix/themes";

import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import ConfirmationService from "primevue/confirmationservice";

import { createApp } from "vue";
import { i18n } from "./locales/i18n";
import vueDebounce from "vue-debounce";

// Enable this line to debug directly from the Theme source code in CumulusComponents
//import { default as DefaultTheme } from "../../../SharedPackages/Npm/CumulusComponents/src/theme/default";
// And disable this line
import { DefaultTheme } from "@cumulus/components";
const MyPreset = definePreset(Aura, DefaultTheme);

export default function configureApp(app: ReturnType<typeof createApp>) {
  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });

  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);

  app.directive("debounce", vueDebounce({ lock: true }));
  app.directive("tooltip", Tooltip);
}
