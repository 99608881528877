<template>
  <div class="flex items-center justify-start gap-2">
    <Checkbox inputId="webshop-active" v-model="active" :binary="true" data-testid="webshop-active" />
    <label for="webshop-active"> {{ t(`webshop.active.label`) }} </label>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const active = defineModel("active", { required: true });
</script>
