import { Address } from "./Address";
import { Banking } from "./Banking";

export class CompanyRegistrationInformation {
  companyName = "";
  email = "";
  firstName = "";
  lastName = "";
  registrationDate = new Date(Date.now()).toJSON();
  organizationNumber = "";
  active = true;
  telephoneNumber = "";
  companyAddress: Address = new Address();
  countryIso = "NO";
  banking: Banking = new Banking();
  currencyIso = "NOK";
}
