import { AddWebshopRequest, UpdateApiCredentialsRequest, UpdateWebshopRequest, webshopApi } from "../api/WebshopApi";
import { Webshop } from "../models/Webshop";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import type { AuthHeaders } from "@cumulus/http";

export function useWebshops() {
  const webshop = ref<Webshop>(new Webshop());
  const webshops = ref<Webshop[]>([]);

  const toast = useCumulusToast(useToast());
  const { navigateToEdit, navigateToSearch } = useNavigation("webshop");
  const { getRequest, mutationRequest, loading, saving, error } = useRequests();

  const fetchWebshops = async () => {
    getRequest(async (authHeaders: AuthHeaders) => {
      webshops.value = await webshopApi.getAll(authHeaders);
    });
  };

  const fetchWebshopById = async (id: string) => {
    getRequest(async (authHeaders: AuthHeaders) => {
      webshop.value = await webshopApi.get(authHeaders, id);
      webshopUnmodified.value = cloneDeep(webshop.value);
    });
  };

  const webshopUnmodified = ref<Webshop>(new Webshop());
  const isChanged = computed(() => {
    return !isEqual(webshop.value, webshopUnmodified.value);
  });

  const createWebshop = async (request: AddWebshopRequest) => {
    if (!(await isFormValid())) {
      return;
    }

    mutationRequest(async (authHeaders: AuthHeaders) => {
      const id = await webshopApi.create(authHeaders, request);
      navigateToEdit(id);
    });
  };

  const updateWebshop = async (webshop: Webshop) => {
    if (!(await isFormValid())) {
      return;
    }

    mutationRequest(async (authHeaders: AuthHeaders) => {
      const request = new UpdateWebshopRequest();
      request.domainName = webshop.domainName;
      request.clientId = webshop.clientId;
      request.warehouseId = webshop.warehouseId;
      request.integrationType = webshop.integrationType;
      await webshopApi.update(authHeaders, webshop.id, request);
      const credRequest = new UpdateApiCredentialsRequest();
      credRequest.apiUrl = webshop.apiUrl;
      credRequest.authUsername = webshop.authUsername;
      credRequest.authPassword = webshop.authPassword;
      await webshopApi.updateApiCredentials(authHeaders, webshop.id, credRequest);
      webshopUnmodified.value = cloneDeep(webshop);
    });
  };

  const deleteWebshop = async (id: string) => {
    mutationRequest(async (authHeaders: AuthHeaders) => {
      await webshopApi.delete(authHeaders, id);
      navigateToSearch();
    });
  };

  const { t } = useI18n();
  const validate = useVuelidate();
  const isFormValid = async (): Promise<boolean> => {
    validate.value.$touch();

    if (!(await validate.value.$validate())) {
      toast.add({
        severity: "warn",
        summary: t("common.toast.warn.summary"),
        detail: t("common.toast.warn.detail"),
        closable: true,
      });
      return false;
    }
    return true;
  };

  return {
    webshops,
    webshop,
    isChanged,
    fetchWebshopById,
    fetchWebshops,
    createWebshop,
    updateWebshop,
    deleteWebshop,
    loading,
    saving,
    error,
  };
}
