import type { Warehouse } from "@/api/warehouse/model/Warehouse";
import { warehouseApi } from "@/api/warehouse/WarehouseApi";
import type { AuthHeaders } from "@cumulus/http";

export function useWarehouses() {
  const warehouses = ref<Warehouse[]>([]);

  const { getRequest, error, loading } = useRequests();

  const fetchWarehouses = async () => {
    getRequest(async (authHeaders: AuthHeaders) => {
      warehouses.value = await warehouseApi.getAll(authHeaders);
    });
  };

  onMounted(fetchWarehouses);

  return { warehouses, loading, error };
}
