<template>
  <FloatLabel variant="on">
    <InputText
      type="text"
      v-model="model"
      data-testid="webshop-name"
      class="w-full"
      :invalid="val.model.$error"
      v-tooltip.bottom="{
        value: tooltip,
        showDelay: 500,
        hideDelay: 300,
        disabled: tooltip === undefined,
      }"
    />
    <label for="webshop-name">{{ label }}</label>
  </FloatLabel>

  <Message v-if="helptext" size="small" severity="secondary" variant="simple">{{ helptext }}</Message>

  <small class="p-error" v-if="val.model.$error" data-testid="webshop-name-error">
    {{ val.model.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { requiredIf } from "@vuelidate/validators";

const model = defineModel<string>({ required: true });

const props = defineProps<{
  label: string;
  required?: boolean;
  tooltip?: string;
  helptext?: string;
}>();

const rules = {
  model: {
    required: requiredIf(() => props.required),
  },
};

const val = useVuelidate(rules, { model });
</script>
