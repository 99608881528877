import { httpClient, type AuthHeaders } from "@cumulus/http";
import type { Webshop } from "../models/Webshop";
import { NIL as emptyUuid } from "uuid";
import { IntegrationType } from "../models/WebshopIntegrationType";

class WebshopApi {
  private uri = "";

  constructor() {
    this.uri = `${import.meta.env.VITE_APP_API_URL as string}/webshops`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Webshop[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, webshopId: string): Promise<Webshop> {
    return await httpClient(this.uri, authHeaders)
      .get(webshopId)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, request: AddWebshopRequest): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .post("", request)
      .then(({ data }) => data.webshopId);
  }

  public async update(authHeaders: AuthHeaders, webshopId: string, request: UpdateWebshopRequest): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/" + webshopId, request);
  }

  public async updateApiCredentials(
    authHeaders: AuthHeaders,
    webshopId: string,
    request: UpdateApiCredentialsRequest,
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/" + webshopId + "/api-credentials", request);
  }

  public async delete(authHeaders: AuthHeaders, webshopId: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(webshopId);
  }

  public async createWebhooks(authHeaders: AuthHeaders, webshopId: string, isLocal: boolean): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/" + webshopId + "/woocommerce/webhooks", null, {
      params: { isLocal: isLocal },
    });
  }

  public async deleteWebhooks(authHeaders: AuthHeaders, webshopId: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete("/" + webshopId + "/woocommerce/webhooks");
  }
}

class AddWebshopRequest {
  domainName: string = "";
  url: string = "";
  clientId: string = emptyUuid;
  warehouseId: string = emptyUuid;
  integrationType: IntegrationType = IntegrationType.WooCommerce;
}

class UpdateWebshopRequest {
  domainName: string = "";
  clientId: string = emptyUuid;
  warehouseId: string = emptyUuid;
  integrationType: IntegrationType = IntegrationType.WooCommerce;
  active: boolean = true;
}

class UpdateApiCredentialsRequest {
  apiUrl: string = "";
  authUsername: string = "";
  authPassword: string = "";
}

const webshopApi = new WebshopApi();
export { webshopApi, AddWebshopRequest, UpdateWebshopRequest, UpdateApiCredentialsRequest };
