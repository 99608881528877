import { NIL as emptyUuid } from "uuid";
import { IntegrationType } from "./WebshopIntegrationType";

export class Webshop {
  id: string = emptyUuid;
  domainName: string = "";
  clientId: string = emptyUuid;
  warehouseId: string = emptyUuid;
  apiUrl: string = "";
  authUsername: string = "";
  authPassword: string = "";
  integrationType: IntegrationType = IntegrationType.None;
  active: boolean = true;
}
