import { h, createApp } from "vue";
import singleSpaVue from "single-spa-vue";
import App from "./App.vue";
import router from "./router/router";
import { msalInstance, msalPlugin } from "./azure-b2c";
import configureApp from "./common";

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App);
    },
  },

  async handleInstance(app) {
    app.use(router);
    app.use(msalPlugin, await msalInstance());

    configureApp(app);

    router.isReady().then(() => {
      app.mount();
    });
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
