import { useAuth } from "@cumulus/event-bus";
import { companyApi } from "./CompanyApi";
import { Company } from "./model/Company";
import { useErrorHandler } from "../ErrorHandler";
import { CompanyRegistrationInformation } from "./model/CompanyRegistrationInformation";

export function useCompany() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCompany = async (id: string): Promise<Company> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await companyApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllCompanies = async (): Promise<Company[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await companyApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createCompany = async (company: CompanyRegistrationInformation): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await companyApi.create(authHeaders, company);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCompany = async (company: Company): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await companyApi.update(authHeaders, company);
    } catch (error) {
      await handleError(error);
    }
  };

  const deleteCompany = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await companyApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
    }
  };

  return {
    getCompany,
    getAllCompanies,
    createCompany,
    updateCompany,
    deleteCompany,
  };
}
