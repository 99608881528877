import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const companyRoutes = [
  {
    name: "company-search",
    path: "/administration/company",
    component: (): Component => import("@/company/view/CompanySearch.vue"),
    meta: {
      authRequired: true,
      title: "company.search-company",
    },
  },
  {
    name: "company-add",
    path: "/administration/company/add",
    component: (): Component => import("@/company/view/CompanyAdd.vue"),
    meta: {
      authRequired: true,
      title: "company.add-company",
    },
  },
  {
    name: "company-edit",
    path: "/administration/company/edit/:id",
    component: (): Component => import("@/company/view/CompanyEdit.vue"),
    meta: {
      authRequired: true,
      title: "company.edit-company",
    },
  },
  {
    name: "webshop-search",
    path: "/administration/webshop",
    component: (): Component => import("@/webshop/view/WebshopSearch.vue"),
    meta: {
      authRequired: true,
      title: "webshop.search-webshop",
    },
  },
  {
    name: "webshop-edit",
    path: "/administration/webshop/edit/:id",
    component: (): Component => import("@/webshop/view/WebshopEdit.vue"),
    meta: {
      authRequired: true,
      title: "webshop.edit-webshop",
    },
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("../components/OAuth2Signout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../components/Failed.vue"),
        },
      ]
    : [];
